.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    background-color: #fff; /* Adjust background color as needed */
    margin-bottom: 50px;
    
  }
  
  .footer-logo {
    width: 100px; /* Adjust width as needed */
    height: auto;
    margin-top: -200px;
    margin-bottom: 120px;
  }
  
  .footer-slogan {
    color: #ffffff;
    font-family: 'Retro Computer', sans-serif; /* Apply custom font */
    text-align: center;
    margin-top: -130px;
  }
  
  .emoji {
    font-size: 28px;
  }