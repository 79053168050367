@font-face {
    font-family: 'Retro Computer';
    src: url('./fonts/PressStart2P-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

body {
  background: hsla(0, 96%, 48%, 1);

  background: linear-gradient(270deg, hsla(0, 96%, 48%, 1) 17%, hsla(0, 0%, 3%, 1) 63%);
  
  background: -moz-linear-gradient(270deg, hsla(0, 96%, 48%, 1) 17%, hsla(0, 0%, 3%, 1) 63%);
  
  background: -webkit-linear-gradient(270deg, hsla(0, 96%, 48%, 1) 17%, hsla(0, 0%, 3%, 1) 63%);
  
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#f10505", endColorstr="#070707", GradientType=1 );
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  justify-content: center;
  padding: 120px;
    
    
  }
  
  .grid-item {
    position: relative;
    overflow: hidden;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
    background-color: #fff;
    padding: 0;
  }
  
  .grid-item:hover {
    transform: scale(1.05);
  }
  
  h1 {
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 60px;
    margin-bottom: -50px;
    color: white;
    font-family: 'Retro Computer', san-serif;
  }

  .thumbnail {
    width: 100%;
    height: 260px;
    object-fit: fill;
    display: block;
    margin: 0;
    padding: 0;
  }
  
  .image-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px;
    font-family: 'Retro Computer', sans-serif; /* Apply custom font */
    position: relative; /* Ensure positioning context for popout */
    background-color: #ffffff;
  }
  
  .text-and-icon {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    white-space: nowrap;
    
  }

  .custom-text {
    color: #007bff;
    text-align: center; /* Center the text */
    margin-right: 2px; /* Add some space between text and icon */
    font-size: 13px;
  }
  
  .share-icon {
    color: #ff3131;
    cursor: pointer;
    font-size: 1.2em;
    margin-left: 8px;
    margin-top: -4px;
    transition: color 0.3s, transform 0.3s;
  }
  
  .share-icon:hover {
    color: #ff3131;
    transform: scale(1.2);
  }
  
  .share-buttons-popout {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 8px;
    position: absolute;
    bottom: 100%;
    top: -60px;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 10;
    white-space: nowrap;
  }

  .pagination {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin-top: -80px;
    margin-bottom: 240px;
  }
  
  .pagination li {
    margin: 0 5px;
    cursor: pointer;
  }
  
  .pagination li a {
    color: #ffffff;
    text-decoration: none;
    padding: 8px 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .pagination li a:hover {
    background-color: #ff3131;
    color: white;
  }
  
  .pagination li.active a {
    font-weight: bold;
    background-color: #ff3131;
    color: white;
  }
  
  /* Media Queries for Responsive Design */
  @media (max-width: 1024px) {
    .grid-container {
      grid-template-columns: repeat(3, 1fr); 
    }
    .thumbnail {
      width: 100%;
      height: 180px;
      object-fit: fill;
      display: block;
      margin: 0;
      padding: 0;
    }
  }
  
  @media (max-width: 768px) {
    .grid-container {
      grid-template-columns: repeat(2, 1fr);
    }
    .thumbnail {
      width: 100%;
      height: 200px;
      object-fit: fill;
      display: block;
      margin: 0;
      padding: 0;
    }
  }
  
  @media (max-width: 480px) {
    .grid-container {
      grid-template-columns: 1fr;
      padding: 80px;
      padding-bottom: 120px;
    }
    .thumbnail {
      width: 100%;
      height: 200px;
      object-fit: fill;
      display: block;
      margin: 0;
      padding: 0;
    }
    .pagination li {
      margin: 0 0px; /* Reduce margin between pagination items */
    }
    .pagination li a {
      padding: 4px 8px; /* Adjust the padding for pagination on mobile */
      
    }  
    .custom-text {
      color: #007bff;
      text-align: center; /* Center the text */
      margin-right: 2px; /* Add some space between text and icon */
      font-size: 13px;
    }

  }